import React, { Component } from "react";
import { Link } from "gatsby";
import { FormattedMessage, injectIntl } from "react-intl";
import Layout from "../components/layout";
import { graphql } from "gatsby";

const DirectLink = injectIntl(({ intl, url }) => (
  <a href={url} title={intl.formatMessage({ id: "downloading.link-title" })}>
    <strong>
      <FormattedMessage id="downloading.link" />
    </strong>
  </a>
));

class Downloading extends Component {
  componentDidMount() {
    const { data } = this.props;
    window.setTimeout(() => {
      window.location = data.site.siteMetadata.downloadLinks.exe.url;
    }, 1500);
  }
  render() {
    const {
      pageContext: { language, title, id },
      data,
    } = this.props;
    return (
      <Layout locale={language} title={title} pageId={id}>

      <div className="max-w-7xl mx-auto mt-8 px-4 sm:px-6  lg:px-8 prose">

        <h1>
          <FormattedMessage id="downloading.content.title" />
        </h1>
        <p>
          <FormattedMessage
            id="downloading.content.text"
            values={{
              link: (
                <DirectLink url={data.site.siteMetadata.downloadLinks.exe.url} />
              ),
            }}
          />
        </p>
        <Link to={data.releaseNoteLink.path} className="flex items-center">
          <svg class="w-6 h-6 inline mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"></path></svg>
          <FormattedMessage id="downloading.content.release-btn" />
        </Link>
        </div>
      </Layout>
    );
  }
}

export default Downloading;

export const query = graphql`
  query downloadingPageQuery($language: String!) {
    site {
      siteMetadata {
        downloadLinks {
          exe {
            url
            size {
              text
            }
          }
        }
      }
    }

    releaseNoteLink: sitePage(
      context: { tags: { eq: "latest" }, language: { eq: $language } }
    ) {
      path
    }
  }
`;
